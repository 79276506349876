<template>
  <div id="invoices">
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name: 'root'}"><i class="fa fa-home"></i></router-link></li>
          <li class="breadcrumb-item"><router-link :to="{name: 'invoices'}">{{ $t('invoices') }}</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{$t(invoice.type.name)}} n° {{ invoice.number }}</li>
        </ol>
      </div>
      <div class="col-md-3">
        <ul class="nav nav-pills">
          <li class="nav-item"><a data-toggle="tab" class="nav-link active" href="#main"> Facture</a></li>
          <li v-if="invoice.locked !== true" class="nav-item"><a data-toggle="tab" class="nav-link" href="#tab_articles" v-on:click="loadArticles()"><i class="fa fa-plus"></i> Articles</a></li>
        </ul>
        <br />
        <div class="tab-content">
          <div class="tab-pane active" id="main">
            <b-overlay :show="showOverlayInvoice" rounded="sm">
              <div class="card">
                <div class="card-body">
                  <form method="post" >
                    <b-form-checkbox switch :checked="invoice.locked" v-on:change="lockInvoice">Lock</b-form-checkbox>
                    <hr />
                    <div class="form-group">
                      <label>{{ $t('customer') }}:</label>
                      <b-input-group>
                        <template v-slot:prepend>
                          <b-input-group-text>
                            <router-link :to="{name: 'customer', params: {'customerId': invoice.customer.id}}">
                              <i class="fa fa-user"></i>
                            </router-link>
                          </b-input-group-text>
                        </template>
                        <b-form-input v-model="invoice.customer.name" v-bind:disabled="true"></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('country') }}:</label>
                      <input type="text" class="form-control" v-model="address.country" disabled/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('city') }}:</label>
                      <input type="text" class="form-control" v-model="address.city" disabled/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('street') }}:</label>
                      <input type="text" class="form-control" v-model="address.street" disabled/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('number') }}:</label>
                      <input type="text" class="form-control" v-model="address.number" disabled/>
                    </div>
                    <hr />

                    <div class="form-group">
                      <label>{{ $t('amount_without_tax') }}:</label>
                      <input type="text" class="form-control" v-bind:value="invoice.amount.amount" disabled/>
                    </div>

                    <div class="form-group" v-for="tax in invoice.amount.taxes">
                      <label>{{ $t('vat') }} {{ tax.rate }} %:</label>
                      <input type="text" class="form-control" v-bind:value="tax.amount" disabled/>
                    </div>

                    <div class="form-group">
                      <label>{{ $t('amount_with_tax') }}:</label>
                      <input type="text" class="form-control" v-bind:value="invoice.amount.amount_incl" disabled/>
                    </div>

                    <hr />
                    <div class="form-group">
                      <label>{{ $t('date') }}:</label>
                      <input type="date" class="form-control" v-model="invoice.date" v-bind:disabled="invoice.locked"/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('number') }}:</label>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="invoice.number" v-bind:disabled="invoice.locked"/>
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">2020/1</span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('deadline_of_invoice') }}:</label>
                      <input type="date" class="form-control datepicker" v-model="invoice.deadline" v-bind:disabled="invoice.locked"/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('comment') }}:</label>
                      <textarea class="form-control" style="padding:5px;height: 100px;" v-model="invoice.comment" v-bind:disabled="invoice.locked"></textarea>
                    </div>
                    <button type="button" v-if="invoice.locked !== true" class="btn btn-primary" v-on:click="updateInvoice(invoice)"><i class="fa fa-save"></i> {{ $t('save') }}</button>
                    <button type="button" v-if="invoice.last === true && invoice.locked !== true" v-on:click="deleteInvoice(invoice)" class="btn btn-danger pull-right" title="Supprimer Facture">
                      <i class="fa fa-trash"></i>
                    </button>
                  </form>
                </div>
              </div>
            </b-overlay>
          </div>
          <div class="tab-pane" id="tab_articles" v-if="invoice.locked !== true">
            <div class="card">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>{{ $t('article') }}</th>
                  <th>{{ $t('price') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody v-if="articles.length===0">
                <tr>
                  <td colspan="5">{{ $t('in_waiting') }}...</td>
                </tr>
                </tbody>
                <tbody v-if="articles.length>0">
                <tr v-for="article in articles">
                  <td>{{ article.name}}</td>
                  <td>
                    <money-format :value="article.price.amount"
                                  locale='en'
                                  :currency-code="invoice.currency.name"
                                  :subunit-value=true
                                  :hide-subunits=false>
                    </money-format>
                  </td>
                  <td>
                    <a href="javascript:;" v-on:click="addArticleInInvoice(article)" class="btn btn-primary">
                      <i class="fa fa-plus"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="col-md-8">
          <h1><money-format :value="invoice.amount.amount_incl"
                            locale='en'
                            :currency-code="invoice.currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
          </money-format></h1>
        </div>
        <div class="col-md-4">&nbsp;</div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-8">
                  <router-link :to="{name: 'payments-target', params: {target: 'document', targetId: invoice.id}}" class="btn btn-primary btn-xs">
                    <i class="fa fa-money"></i> Détails de paiement
                  </router-link>
                  <router-link v-if="invoice.locked !== true" :to="{name: 'sale-invoice-new', params: {invoiceId: invoice.id}}" class="btn btn-success btn-xs">
                    <i class="fa fa-shopping-cart"></i> {{ $t('add_one_sale') }}
                  </router-link>
                  <b-button v-if="invoice.locked !== true" v-b-modal.modal-invoice-add-discount class="btn btn-info btn-xs"><i class="fa fa-gift"></i> {{ $t('add') }} {{ $t('reduction') }}</b-button>
                </div>
                <div class="col-md-4" style="text-align: right">
                  <a v-bind:href="invoiceCheckoutUrl" target="alt" class="btn btn-default btn-xs" v-if="invoice.amount.left > 0 && invoiceCheckoutUrl">Lien de paiement <i class="fa fa-credit-card"></i></a>
                  <a v-bind:href="invoicePrintPdfUrl" target="alt"  class="btn btn-default btn-xs" v-if="invoicePrintPdfUrl"><i class="fa fa-print"></i></a>
                  <div class="btn-group">
                    <button class="btn btn-default btn-xs dropdown-toggle dropdown-toggle-split" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <i class="fa fa-send"></i> Envoyer facture
                    </button>
                    <ul class="dropdown-menu">
                      <a v-if="invoice.has.postal" class="dropdown-item" v-on:click="sendInvoice(invoice.id, 'postal')">Via <img src="//api.begels.com/img/bpost.png" style="max-width: 16px;max-height: 16px;" alt=""/></a>
                      <a v-if="invoice.has.email" class="dropdown-item" v-on:click="sendInvoice(invoice.id, 'email')">Via {{ $t('by_email') }}</a>
                      <a v-if="invoice.has.fax"  class="dropdown-item" v-on:click="sendInvoice(invoice.id, 'fax')">Via fax <span style="color:#8f8f8f">0.12 Cr</span></a>
                      <a v-if="invoice.has.mobile"  class="dropdown-item" v-on:click="sendInvoice(invoice.id, 'sms')">Via <i class="fa fa-mobile"></i> <span style="color:#8f8f8f">0.12 Cr</span></a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <b-overlay :show="showOverlaySales" rounded="sm">
            <div class="card">
              <table class="table table-striped table-hover table-bordered specialCollapse">
                <thead>
                <tr>
                  <th>Paiement</th>
                  <th>{{ $t('date') }}</th>
                  <th>{{ $t('title') }}</th>
                  <th colspan="3">{{ $t('amount') }}</th>
                  <th></th>
                </tr>
                <tr>
                  <th colspan="3"></th>
                  <th>{{ $t('tva') }}</th>
                  <th>{{ $t('htva') }}</th>
                  <th>{{ $t('tvac') }}</th>
                  <th colspan="10"></th>
                </tr>
                <tr>
                  <th colspan="10">
                    <b-progress-bar :value="invoicePcPayed" :max="100" show-progress show-value>{{invoicePcPayed}} %</b-progress-bar>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="sales.length===0">
                  <td colspan="15">{{ $t('any_sale_for_the_moment') }}...</td>
                </tr>
                <tr v-if="sales.length>0" v-for="sale in sales" v-bind:class="sale._trClass">
                  <td>
                    <router-link :to="{name: 'payments-target', params: {target: 'sale', targetId: sale.id}}" type="button" :class="btnPayedClass(sale.amount.payed_pc)">
                      {{sale.amount.payed_pc | round }} %
                    </router-link>
                  </td>
                  <td>{{sale.date}}</td>
                  <td>
                    <router-link v-if="sale.article && sale.article.id" :to="{name: 'article', params: {articleId: sale.article.id}}">{{sale.title}}</router-link>
                    <span v-else>{{sale.title}}</span>
                  </td>
                  <td>
                    <money-format :value="sale.amount.amount_tax"
                                  locale='en'
                                  :currency-code="sale.currency.name"
                                  :subunit-value=true
                                  :hide-subunits=false>
                    </money-format>
                  </td>
                  <td>
                    <money-format :value="sale.amount.amount"
                                  locale='en'
                                  :currency-code="sale.currency.name"
                                  :subunit-value=true
                                  :hide-subunits=false>
                    </money-format>
                  </td>
                  <td>
                    <money-format :value="sale.amount.amount_incl"
                                  locale='en'
                                  :currency-code="sale.currency.name"
                                  :subunit-value=true
                                  :hide-subunits=false>
                    </money-format>
                  </td>
                  <td>
                    <router-link v-if="invoice.locked !== true" :to="{name: 'sale', params: {saleId: sale.id}}" class="btn btn-xs btn-info">
                      <i class="fa fa-edit"></i>
                    </router-link>
                    <a href="javascript:;" v-if="invoice.locked !== true" v-on:click="deleteSale(sale)" class="btn btn-danger btn-xs confirmButton" title="Supprimer la vente">
                      <i class="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            @change="loadPaginationSales"
          ></b-pagination>
        </div>
      </div>
    </div>
    <send-postal></send-postal>
    <add-discount></add-discount>
  </div>
</template>

<script>
  import AddDiscount from './Modal/AddDiscount'
  import SendPostal from './Modal/SendPostal'
  import MoneyFormat from 'vue-money-format'
  import {BProgressBar, BOverlay, BButton, BInputGroup, BInputGroupText, BFormInput, VBModal, BFormCheckbox, BPagination} from 'bootstrap-vue'

  export default {
    components: {
      AddDiscount,
      SendPostal,
      'b-button':BButton,
      'b-progress-bar':BProgressBar,
      'b-overlay':BOverlay,
      'b-input-group':BInputGroup,
      'b-input-group-text':BInputGroupText,
      'b-form-input':BFormInput,
      'b-form-checkbox': BFormCheckbox,
      'b-pagination' : BPagination,
      'money-format': MoneyFormat,
    },
    directives: {
        'b-modal': VBModal
    },
    data () {
      return {
        invoice: {
          amount: {},
          customer: {},
          currency: {},
          address: {},
          type: {
            name: null,
          },
          has: {
              postal: false,
              email: false,
              mobile: false,
              fax: false
          },
          url: {
              payment: null
          }
        },
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        rows: 0,
        address: {},
        articles: [],
        sales: [],
        showOverlayInvoice: false,
        showOverlaySales: false
      }
    },
    mounted () {
      this.loadInvoice(this.$route.params.invoiceId, true);
    },
    computed: {
        invoicePcPayed: function () {
            return this.invoice.amount.payed_pc > 100 ? 100 : this.invoice.amount.payed_pc;
        },
        invoicePrintPdfUrl : function () {
          return 'undefined' !== typeof this.invoice.external_links && 'undefined' !== typeof this.invoice.external_links.pdf ? this.invoice.external_links.pdf : null;
        },
        invoiceCheckoutUrl : function () {
          return 'undefined' !== typeof this.invoice.external_links && 'undefined' !== typeof this.invoice.external_links.checkout ? this.invoice.external_links.checkout : null;
        },
    },
    methods : {
      btnPayedClass: function (percent) {
          if (percent >= 100) {
              return 'btn btn-success btn-xs';
          } else {
              return 'btn btn-default btn-xs';
          }
      },
      deleteSale : function(sale) {
        this.$http.delete('sale/'+sale.id).then((response) => {
          if (response.ok === true) {
            let index = this.sales.indexOf(sale);
            this.sales.splice(index, 1);
            this.loadInvoice(sale.invoice.id);
          }
        }).catch((response) => {
          this.loadInvoice(sale.invoice.id);
        });
      },
      loadInvoice : function (invoiceId, cascade) {
        this.showOverlayInvoice = true;
        this.$parent.setApplicationSpinner(true);
        this.$http.get('document/'+invoiceId).then((response) => {
          this.$parent.setApplicationSpinner(false);
          this.invoice = response.data;
          this.showOverlayInvoice = false;
          if (cascade === true) {
            this.loadSales(invoiceId);
            this.loadAddress(invoiceId);
          }
        }).catch((response) => {
            this.showOverlayInvoice = false;
        });
      },
      addArticleInInvoice : function (article) {
        this.$http.post('document/'+this.invoice.id+'/article/'+article.id).then((response) => {
          if (response.ok === true) {
              this.sales.push(response.data);
              this.loadInvoice(false);
          }
        });
      },
      deleteInvoice : function(invoice) {
          this.$http.delete('document/'+invoice.id).then((response) => {
              if (response.ok === true) {
                  this.$router.push({ name: "invoices"});
              }
          });
      },
      updateInvoice : function(invoice) {
          this.showOverlayInvoice = true;
          this.$http.put('document/'+invoice.id, {
              date: invoice.date,
              deadline: invoice.deadline,
              number: invoice.number,
              comment: invoice.comment
          }).then((response) => {
              this.showOverlayInvoice = false;
              if (response.ok === true) {
                  this.invoice = response.data;
              }
          }).catch((response) => {
              this.showOverlayInvoice = false;
          });
      },
      lockInvoice : function() {
        this.$http.put('document/'+this.invoice.id+'/lock').then((response) => {
          if (response.ok === true) {
            this.invoice.locked = !this.invoice.locked;
          }
        });
      },
      sendInvoice: function (invoiceId, mode) {
          this.$http.post('document/'+invoiceId+'/send/'+mode).then((response) => {
              if (response.ok === true) {
                  alert('Send: Successful');
              } else {
                  alert('Send: Error');
              }
          });
      },
      loadAddress : function (invoiceId) {
        if (invoiceId) {
          this.$http.get('document/' + invoiceId + '/address/customer').then((addressResponse) => {
            this.address = addressResponse.data;
          });
        }
      },
      loadPaginationSales: function (pageNum) {
          this.showOverlaySales = true;
          this.currentPage = pageNum;
          this.loadSales();
      },
      loadSales : function (invoiceId) {
        this.showOverlaySales = true;
        this.$http.get('document/'+invoiceId+'/items?page='+this.currentPage).then((response) => {
          this.showOverlaySales = false;
          this.rows = response.data.pagination.result;
          this.perPage = response.data.pagination.by_page;
          this.nbPages = response.data.pagination.pages;
          this.sales = response.data.items;
        }).catch((response) => {
            this.showOverlaySales = false;
        });
      },
      loadArticles (invoiceId) {
        if (invoiceId) {
            this.$http.get('document/' + invoiceId + '/articles?page=1').then((articlesResponse) => {
                this.articles = articlesResponse.data.items;
            });
        }
      }
    }
  }
</script>
