<template>
  <div class="modal fade" id="goSale" ref="modalGoSale" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form method="post">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel"><i class="fa fa-check"></i> {{ $t('sale') }} <span v-if="till.customer">{{ till.customer.name }}</span></h4>
          </div>
          <div class="modal-body" style="text-align: center;">
            <b-form-group :label="$t('type')">
              <b-form-radio-group id="radio-group-1" v-model="invoiceType" name="radio-invoice-type">
                <b-form-radio :value="invoiceType.id" v-for="invoiceType in invoiceTypes" v-bind:key="invoiceType.id">{{$t(invoiceType.name)}}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <br /><br />
            <b-form-group :label="$t('paymode')">
              <b-form-radio-group id="radio-group-2" v-model="paymode" name="radio-paymode">
                <b-form-radio value="">{{ $t('unpayed') }}</b-form-radio>
                <b-form-radio v-for="factoryPaymode in factoryPaymodes" v-bind:key="factoryPaymode.id" v-bind:value="factoryPaymode">{{$t(factoryPaymode.name)}}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <br /><br />
            <button type="button" class="btn btn-success btn-lg" v-on:click="saleTill"><i class="fa fa-shopping-cart fa-5x"></i></button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">{{ $t('close') }} <i class="fa fa-sign-out"></i></button>
          </div>
        </div>
      </div>
    </form>
  </div>

</template>

<script>
    import {BFormGroup, BFormRadio, BFormRadioGroup} from 'bootstrap-vue';
    import store from '../../../store'
    export default {
        name: "pos-go-sale",
        components: {
            'b-form-group': BFormGroup,
            'b-form-radio': BFormRadio,
            'b-form-radio-group': BFormRadioGroup,
        },
        props: {
            tillId: {
                type: String,
                required: false,
            },
        },
        data () {
          return {
            factoryPaymodes: [],
            paymode: null,
            invoiceType: null,
            invoiceTypes: []
          };
        },
        mounted : function () {
            this.loadPaymodes();
            this.loadInvoiceTypes();
        },
        computed: {
          till : function () {
            return this.$parent.till;
          },
          taxes : function () {
              return this.$parent.taxes;
          }
        },
        methods: {
          loadInvoiceTypes: function () {
              this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/invoice/types').then((response) => {
                  if (response.ok === true) {
                      this.invoiceTypes = response.data;
                  }
              });
          },
          loadPaymodes: function () {
            this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/payment/means').then((response) => {
                if (response.ok === true) {
                    this.factoryPaymodes = response.data;
                }
            });
          },
          saleTill : function () {
            this.$http.post('pos/till/'+this.tillId+'/sales', {
                invoice_type_id: this.invoiceType,
                paymode_id: this.paymode.id
            }).then((response) => {
              if (response.ok === true) {
                this.$parent.$parent.loadTill(this.tillId);
                $("#goSale").modal('hide');
              }
            }).catch((response) => {
              if (response.data && response.data.message) {
                this.$parent.alert.type = 'alert-error';
                this.$parent.alert.message = response.data.message;
              }
            });
          }
        }
    }
</script>

<style scoped>

</style>
