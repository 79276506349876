<template>
  <div id="app">
    <nav class="navbar navbar-light bg-light" style="background-color:#3F6BEC !important;min-height: 30px;">
      <router-link :to="{name: 'root'}" class="navbar-brand">
        <i :class="flagClass"></i>
        {{apps}}
      </router-link>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="container-fluid">
          <div class="col-md-12 mt-5" >
            <b-overlay :show="showOverlayTills" rounded="sm">
              <b-table striped hover :items="tills" :fields="fields">
                <template v-slot:cell(id)="data">
                  <router-link v-if="data.value" :to="{name: 'pos', params: {tillId: data.value}}" class="btn btn-primary">
                    <i class="fa fa-arrow-left"></i>
                  </router-link>
                  <b-button class="btn btn-danger" v-on:click="closeTill(data.value)"><i class="fa fa-trash"></i></b-button>
                </template>
                <template v-slot:cell(user)="data">
                  <router-link v-if="data.value" :to="{name: 'user', params: {userId: data.value.id}}">{{data.value.name}}</router-link>
                </template>
                <template v-slot:cell(customer)="data">
                  <router-link v-if="data.value" :to="{name: 'customer', params: {customerId: data.value.id}}">{{data.value.name}}</router-link>
                </template>
                <template v-slot:cell(amount)="data">
                  <money-format v-if="data.value" :value="data.value"
                                locale='en'
                                :currency-code="currency.name"
                                :subunit-value=true
                                :hide-subunits=false>
                  </money-format>
                </template>
                <template v-slot:cell(items)="data">
                  {{data.value.length>0?data.value.length:''}}
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '../store'
  import {BCol, BTable, BButton, BOverlay} from 'bootstrap-vue'
  import MoneyFormat from 'vue-money-format'

  export default {
    components: {
        BButton,
        BCol,
        BTable,
        BOverlay,
        MoneyFormat
    },
    name: 'pos-tills',
    data () {
      return {
        tills: [],
        showOverlayTills: false,
        fields: [
            {
                key: 'id',
                label: null,
            },
            {
                key: 'user',
                label: 'owner',
            },
            {
                key: 'customer',
                label: 'customer',
            },
            {
                key: 'amount',
                label: 'amount',
            },
            {
                key: 'items',
                label: 'Items',
            },
        ]
      };
    },
    mounted () {
        this.loadTills();
    },
    computed: {
      flagClass : function() {
          return 'flag-icon flag-icon-be';
      },
      currency: function () {
          return store.state.factoryConnected.currency;
      }
    },
    methods: {
      loadTills : function() {
          this.showOverlayTills = true;
          this.$http.get('factory/'+store.state.factoryConnected.id+'/pos/till?page=1').then((response) => {
              this.showOverlayTills = false;
              if (response.ok === true) {
                  this.tills = response.data.items;
              }
          });
      },
      closeTill : function(tillId) {
          this.showOverlayTills = true;
          this.$http.put('till/'+tillId+'/close').then((response) => {
              this.showOverlayTills = false;
              if (response.ok === true) {
                  this.loadTills();
              }
          });
      }
    }

  }
</script>
