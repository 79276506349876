<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('company_information') }} <small><i class="fa fa-cog"></i> {{ $t('settings') }}</small></h1>
          <hr />
        </div>

        <div class="col-md-3">
          <settings-menu></settings-menu>
        </div>

        <div class="col-md-9">
          <b-overlay :show="showOverlayInvoice" rounded="sm">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label class="control-label col-xs-4">API Key:</label>
                  <div class="col-xs-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">
                            <a href="javascript:void(0);" v-on:click="showKey"><i class="fa fa-eye"></i></a>
                          </span>
                      </div>
                      <input type='text' class="form-control" v-model="secretKey" disabled/>
                      <div class="input-group-prepend">
                          <span class="input-group-text">
                            <a href="javascript:void(0);" v-on:click="resetKey"><i class="fa fa-refresh"></i></a>
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h2>Space</h2>
                <hr />
                <div class="form-group">
                  <label class="control-label col-xs-4">Visible sur le Space:</label>
                  <div class="col-xs-6">
                    <b-form-checkbox v-model="factory.public" name="check-button" switch>
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('category') }}:</label>
                  <div class="col-xs-6">
                    <div class='input-group'>
                      <select class="form-control" name="fk_category" v-model="factory.category">
                        <option value="">Séléctionner une catégorie</option>
                        <option v-for="category in categories" v-bind:selected="factory.category && factory.category.id === category.id" v-bind:value="category">{{category.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('sub_category') }}:</label>
                  <div class="col-xs-6">
                    <div class='input-group'>
                      <select class="form-control" name="fk_category2" v-model="factory.category2">
                        <option value="">Séléctionner une sous-catégorie</option>
                        <option v-for="category in categories" v-bind:selected="factory.category2 && factory.category2.id === category.id" v-bind:value="category">{{category.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('activity') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type="text" class="form-control" v-model="factory.activity"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('description') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <textarea class="form-control" v-model="factory.description"></textarea>
                    </div>
                  </div>
                </div>
                <h2>Informations légales</h2>
                <hr />
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('company') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control" v-model="factory.name"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('owner') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control" v-model="factory.owner"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('vat_number') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control" v-model="factory.vat" v-bind:placeholder="factory.country ? factory.country.code : null" />
                    </div>
                  </div>
                </div>
                <hr />
                <h2>Coordonnées</h2>
                <hr />
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('email') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control" v-model="factory.email" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('country') }}:</label>
                  <div class="col-xs-6">
                    <div class='input-group'>
                      <select class="form-control">
                        <option value="">{{ $t('country_list') }}</option>
                        <option v-for="country in countries" v-bind:selected="factory.country && factory.country.id === country.id">{{country.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('address') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control"  v-model="address.id" disabled/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('city') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control"  v-model="address.city"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('zipcode') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control"  v-model="address.zipcode"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('street') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control"  v-model="address.street"/>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('number') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control"  v-model="address.house"/>
                    </div>
                  </div>
                </div>
                <hr />
                <h2>Informations de contact</h2>
                <hr />
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('phone') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control" v-model="factory.phone" v-bind:placeholder="factory.country.phone" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('mobile') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control" v-model="factory.mobile" v-bind:placeholder="factory.country.phone" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('website') }}:</label>
                  <div class="col-xs-6">
                    <div class='input-group'>
                      <span class="input-group-addon">http://</span>
                      <input type='text' class="form-control" v-model="factory.website"/>
                    </div>
                  </div>
                </div>
                <hr />
                <h2>{{ $t('invoice') }}</h2>
                <hr />
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('bank_account') }}:</label>
                  <div class="col-xs-6">
                    <div class='input'>
                      <input type='text' class="form-control" v-model="factory.bank" v-bind:placeholder="factory.country.code" />
                    </div>
                  </div>
                </div>
                <hr />
                <h2>Informations régionales</h2>
                <hr />
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('timezone') }}:</label>
                  <div class="col-xs-6">
                    <div class='input-group'>
                      <select class="form-control" v-model="factory.timezone">
                        <option value="">{{ $t('select') }} {{ $t('timezone') }}</option>
                        <option v-for="timezone in timezones" v-bind:selected="factory.timezone && factory.timezone.id === timezone.id" v-bind:value="timezone">{{timezone.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">{{ $t('currency') }}:</label>
                  <div class="col-xs-6">
                    <div class='input-group'>
                      <select class="form-control" v-model="factory.currency">
                        <option value="">{{ $t('select') }} {{ $t('currency') }}</option>
                        <option v-for="currency in currencies" v-bind:selected="factory.currency && factory.currency.id === currency.id" v-bind:value="currency">{{currency.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-xs-4">&nbsp;</label>
                  <div class="col-xs-6">
                    <button class="btn btn-success" v-on:click="updateFactory">{{ $t('save') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
</template>

<script>
    import SettingsMenu from "./SettingsMenu";
    import {BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import store from '../../store'

    export default {
      components: {
          SettingsMenu,
          'b-overlay':BOverlay,
          'b-form-checkbox':BFormCheckbox
      },
      name: "settings",
        data () {
          return {
            factory : {
              id: null,
              name: null,
              public: null,
              category: {id: null},
              category2: {id: null},
              activity: null,
              description: null,
              owner: null,
              country: {id: null, code: null, phone: null},
              timezone: {id: null, code: null},
              currency: {id: null, code: null},
              email: null,
              phone: null,
              mobile: null,
              website: null,
              bank: null,
            },
            address: {
              id: null,
              city: null,
              street: null,
              zipcode: null
            },
            countries: [],
            categories: [],
            timezones: [],
            currencies: [],
            secretKey: null,
            showOverlayInvoice: false
          }
        },
        mounted () {
          this.loadData();
        },
        methods: {
          loadData : function () {
            this.$http.get('data/country').then((response) => {
              if (response.ok === true) {
                this.countries = response.data;
              }
            });
            this.$http.get('data/category/factory').then((response) => {
              if (response.ok === true) {
                this.categories = response.data;
              }
            });
            this.$http.get('data/timezone').then((response) => {
              if (response.ok === true) {
                this.timezones = response.data;
              }
            });
            this.$http.get('data/currency').then((response) => {
              if (response.ok === true) {
                this.currencies = response.data;
              }
            });
            this.$http.get('factory/'+store.state.factoryConnected.id).then((response) => {
              if (response.ok === true) {
                let factory = response.data;
                if (typeof factory.category !== 'object' || null === factory.category) {
                  factory.category = {id: null, name: null}
                }
                if (typeof factory.category2 !== 'object' || null === factory.category2) {
                  factory.category2 = {id: null, name: null}
                }
                if (typeof factory.currency !== 'object' || null === factory.currency) {
                  factory.currency = {id: null, name: null}
                }
                if (typeof factory.timezone !== 'object' || null === factory.timezone) {
                  factory.timezone = {id: null, name: null}
                }
                this.factory = factory;
              }
            });
          },
          updateFactory : function () {
              this.showOverlayInvoice = true;
              console.log('updateFactory', this.factory);
              this.$http.put('factory/'+store.state.factoryConnected.id+'/settings', this.factory).then((response) => {
                  if (response.ok === true) {
                      this.loadMineFactory();
                  } else {
                      this.showOverlayInvoice = false;
                  }
              }).catch((response)=>{
                  this.showOverlayInvoice = false;
              });
          },
          showKey : function () {
              this.showOverlayInvoice = true;
              this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/key').then((response) => {
                  this.showOverlayInvoice = false;
                  if (response.ok === true) {
                      this.secretKey = response.data.secret;
                  } else {
                      this.showOverlayInvoice = false;
                  }
              }).catch((response)=>{
                  this.showOverlayInvoice = false;
              });
          },
          resetKey : function () {
            this.showOverlayInvoice = true;
            this.$http.put('factory/'+store.state.factoryConnected.id+'/settings/key').then((response) => {
              this.showOverlayInvoice = false;
              if (response.ok === true) {
                  this.secretKey = response.data.secret;
              } else {
                  this.showOverlayInvoice = false;
              }
            }).catch((response)=>{
                this.showOverlayInvoice = false;
            });
          }
        }
    }
</script>
