<template>
  <div>
    <div class="row">
      <div class="col-md-5">
        <b-overlay :show="showOverlaySale" rounded="sm">
          <form id="formAddInvoice" class="form" method="post" v-on:submit.prevent="submitSale">
            <div class="card">
              <div class="card-header">
                <h4 class="modal-title" v-if="currentSale.id"><i class="fa fa-edit"></i> {{$t('sale')}} n°{{currentSale.id}}</h4>
                <h4 class="modal-title" v-else><i class="fa fa-edit"></i> {{$t('new_sale')}} for {{ currentCustomer.name }}</h4>
              </div>
              <div class="card-body">
                <div class="form-group" v-if="currentCustomer.id">
                  <label>{{ $t('customer') }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <router-link :to="{'name':'customer', params:{'customerId':currentCustomer.id}}" class="fa fa-user"></router-link>
                      </span>
                    </div>
                    <input type="text" v-bind:value="currentCustomer.name" class="form-control" disabled/>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{$t('date')}}:</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                    </div>
                    <input type="date" class="form-control" v-model="currentSale.date" required :disabled="allowForm === false"/>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{$t('title')}}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-pencil"></i></span>
                    </div>
                    <input type='text' class="form-control" v-model="currentSale.title" v-bind:placeholder="$t('title')" autocomplete="off" required :disabled="allowForm === false"/>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{$t('vat_rate')}}:</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">%</span>
                    </div>
                    <select v-model="currentSale.vat_rate" class="form-control" required :disabled="allowForm === false">
                      <option value="">Selectionner un taux de T.V.A.</option>
                      <option v-for="vat_rate in vat_rates" v-bind:value="vat_rate.rate">{{vat_rate.rate}} %</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{$t('amount')}} H.T.:</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-euro"></i></span>
                    </div>
                    <input type="text" v-model="currentSale.amount.amount" class="form-control" v-bind:placeholder="$t('amount')" required :disabled="allowForm === false"/>
                  </div>
                </div>
                <div class="form-group" v-if="currentInvoice.id">
                  <label for="add_invoice_to_invoice">Add to {{$t('invoice')}}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <router-link :to="{name:'invoice', params:{invoiceId: currentInvoice.id}}">
                          <i class="fa fa-file"></i>
                        </router-link>
                      </span>
                    </div>
                    <input type="text" id="add_invoice_to_invoice" class="form-control" v-bind:value="currentInvoice.number" disabled/>
                    <button v-if="invoiceId === null" type="button" class="btn btn-xs btn-danger" v-on:click="resetInvoice"><i class="fa fa-trash"></i></button>
                  </div>
                </div>
                <div class="form-group" v-else-if="currentSale.invoice && currentSale.invoice.id">
                  <label for="set_invoice_to_invoice">{{$t('invoice')}}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <router-link :to="{name:'invoice', params:{invoiceId: currentSale.invoice.id}}">
                          <i class="fa fa-file"></i>
                        </router-link>
                      </span>
                    </div>
                    <input type="text" id="set_invoice_to_invoice" class="form-control" v-bind:value="currentSale.invoice.name" disabled/>
                  </div>
                </div>
                <div class="form-group" v-else-if="currentSale.invoice === null || currentSale.invoice.id === null">
                  <label for="sale_invoices">{{$t('invoices')}}</label>
                  <select class="form-control" id="sale_invoices" v-model="currentInvoice" :disabled="invoices.length===0">
                    <option value="">Select one invoice</option>
                    <option v-for="invoice in invoices" :disabled="invoice.locked" v-bind:value="invoice">{{ $t(invoice.name) }} {{ invoice.number }}</option>
                  </select>
                </div>
                <div class="form-group" v-if="currentSale.amount.payed_pc">
                  <label>{{$t('payed')}}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <router-link :to="{name:'payments-target', params:{target:'sale', targetId: currentSale.id}}"><i class="fa fa-money"></i></router-link>
                      </span>
                    </div>
                    <input type='text' class="form-control" v-bind:value="currentSale.amount.payed" disabled/>
                  </div>
                </div>
              </div>
              <div class="card-footer" v-if="canEdit === true || canCreate === true || canDelete === true">
                <button type="submit" class="btn btn-success"  v-if="canEdit === true">
                  <i class="fa fa-edit"></i>
                  {{ $t('edit') }}
                </button>
                <button type="submit" class="btn btn-primary"  v-if="canCreate === true">
                  <i class="fa fa-plus"></i>
                  {{ $t('add') }}
                </button>
                <button type="button" class="btn btn-danger" v-if="canDelete === true">
                  <i class="fa fa-trash"></i>
                  {{ $t('delete') }}
                </button>
              </div>
            </div>
          </form>
        </b-overlay>
      </div>
      <div class="col-md-7" v-if="currentSale.id">
        <payment :target="'sale'" :target-id="currentSale.id"></payment>
      </div>
    </div>
  </div>
</template>
<script>
    import store from '../../store'
    import {BOverlay} from 'bootstrap-vue'
    import Payment from './../Payments/Payments'
    export default {
        name: "sale",
        components: {
            'payment': Payment,
            'b-overlay': BOverlay
        },
        data () {
          return {
            vat_rates: [],
            currentInvoice: {
                id: null,
                name: null,
            },
            currentCustomer: {
                id: null,
                name: null,
            },
            currentSale: {
                id: null,
                _option: {},
                mapping: {},
                customer: {},
                amount: {},
                invoice: {
                    id: null,
                    name: null
                },
                allow: {
                    edit: false,
                    delete: false
                }
            },
            invoices: [],
            showOverlaySale: false,
          }
        },
        props: {
            saleId: {
                type: String,
                required: false,
                default: function () {
                    return this.$route.params.saleId ? this.$route.params.saleId : null
                }
            },
            customerId: {
                type: String,
                required: false,
                default: function () {
                    return null
                },
            },
            invoiceId: {
                type: String,
                required: false,
                default: function () {
                    return null
                }
            }
        },
        mounted () {
          this.loadVatRates();
          this.loadSale();
        },
        computed: {
            allowForm : function () {
                return this.canCreate === true || this.canEdit === true;
            },
            canCreate: function () {
                return this.currentSale.id === null;
            },
            canEdit: function () {
                if (this.currentSale.id === null) {
                    return false;
                } else if (typeof this.currentSale.allow === 'undefined') {
                    return false;
                }
                return this.currentSale.allow.edit;
            },
            canDelete: function () {
                if (this.currentSale.id === null) {
                    return false;
                } else if (typeof this.currentSale.allow === 'undefined') {
                    return false;
                }
                return this.currentSale.allow.delete;
            },
        },
        methods: {
          getVatRate : function () {
              if (typeof this.currentSale.amount !== 'undefined' && typeof this.currentSale.amount.taxes !== 'undefined') {
                  let taxes = this.currentSale.amount.taxes;
                  for (let i in taxes) {
                      let tax = taxes[i];
                      if (tax.name === 'vat') {
                          return tax.rate;
                      }
                  }
              }
              return null;
          },
          loadChildren: function () {
              this.loadInvoice();
              this.loadCustomer();
          },
          loadSale : function() {
              if (this.saleId) {
                  this.showOverlaySale = true;
                  this.$http.get('sale/'+this.saleId).then((response) => {
                      this.showOverlaySale = false;
                      this.currentSale = response.data;
                      this.currentSale.vat_rate = this.getVatRate();
                      this.loadChildren();
                  }).catch ((response)=>{
                      this.showOverlaySale = false;
                  });
              } else {
                  this.loadChildren();
              }
          },
          loadVatRates : function () {
            this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/tax/vat_rate').then((response) => {
              if (response.ok === true) {
                this.vat_rates = response.data;
              }
            });
          },
          loadCustomer : function () {
              if (this.customerId) {
                  this.$http.get('customer/' + this.customerId).then((response) => {
                      if (response.ok === true) {
                          this.currentCustomer = response.data;
                          this.loadCustomerInvoices();
                      }
                  });
              }
          },
          resetInvoice: function () {
            this.currentInvoice = {
                id: null
            };
          },
          loadInvoice : function () {
              if (this.invoiceId) {
                  this.$http.get('invoice/' + this.invoiceId).then((response) => {
                      if (response.ok === true) {
                          this.currentInvoice = response.data;
                      }
                  });
              }
          },
          loadCustomerInvoices : function () {
              if (null === this.invoiceId && this.customerId) {
                  this.$http.get('customer/' + this.customerId + '/invoices').then((response) => {
                      if (response.ok === true) {
                          this.invoices = response.data;
                      }
                  });
              }
          },
          submitSale: function () {
              if (null !== this.saleId) {
                  return this.updateSale();
              } else {
                  return this.createSale();
              }
          },
          createSale : function() {
              let url;
              if (this.currentInvoice) {
                  url = 'invoice/'+this.currentInvoice.id+'/sale';
              } else if (this.currentCustomer) {
                  url = 'customer/'+this.currentInvoice.customer.id+'/sale';
              }
              if ('' !== url) {
                  this.showOverlaySale = true;
                  this.$http.post(url, this.currentSale).then((response) => {
                      this.showOverlaySale = false;
                      if (response.ok === true) {
                          this.saleId = response.data.id;
                          this.loadSale();
                          if (this.currentInvoice) {
                              return this.$router.push({
                                  name: 'sale-invoice',
                                  params: {
                                      invoiceId: this.currentInvoice.id,
                                      saleId: response.data.id
                                  }
                              });
                          } else if (this.currentCustomer) {
                              return this.$router.push({
                                  name: 'sale-customer',
                                  params: {
                                      invoiceId: this.currentInvoice.id,
                                      saleId: response.data.id
                                  }
                              });
                          }
                      }
                  }).catch((response) => {
                      this.showOverlaySale = false;
                  });
              }
          },
          updateSale : function() {
              this.showOverlaySale = true;
              this.$http.put('sale/'+this.saleId, this.currentSale).then((response) => {
                  this.showOverlaySale = false;
                  this.saleId = response.data.id;
                  this.loadSale();
              }).catch ((response)=>{
                  this.showOverlaySale = false;
              });
          },
          deleteSale : function () {
              this.$http.delete('sale/'+this.saleId).then((response) => {
                  if (response.ok === true) {
                      return this.$router.push({name: 'customer-sales', params: {customerId: this.customerId}});
                  }
              }).catch((response) => {
                this.loadSales();
              });
          }
        }
    }
</script>

<style scoped>

</style>
